<template>
  <v-card class="introduction-carousel" light elevation="0" style="min-height: 700px;">
    <v-btn
        absolute light fab top right flat
        :large="$vuetify.breakpoint.smAndUp"
        style="margin-right: -15px; margin-top: 40px;"
        @click="onClose">
      <v-icon>close</v-icon>
    </v-btn>

    <v-btn
        v-if="steps.length > 1 && currentStep > 0"
        class="navigation-button left"
        fab flat
        :large="$vuetify.breakpoint.smAndUp"
        @click="currentStep--">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
        v-if="steps.length > 1 && currentStep < steps.length -1"
        class="navigation-button right"
        fab flat
        :large="$vuetify.breakpoint.smAndUp"
        @click="currentStep++">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>

    <primary-button
        v-if="currentStep === steps.length - 1"
        class="action-button"
        large
        @click="onClose">
      {{$t('app.home.introduction.action')}}
    </primary-button>

    <v-card-text class="pa-0" style="height:100%; min-height:700px;">
      <v-carousel v-model="currentStep"
                  light
                  :prev-icon="false"
                  :next-icon="false"
                  :hide-delimiters="currentStep === steps.length - 1"
                  :hide-controls="currentStep === steps.length - 1"
                  :interval="15000000"
                  :continuous="false"
                  height="100%"
                  style="min-height:700px;"
                  :class="{
                    'mobile': $vuetify.breakpoint.smAndDown,
                    'desktop': $vuetify.breakpoint.mdAndUp,
                    'pa-3': true,
                  }"
                  :cycle="false">
        <v-carousel-item v-for="(step,index) in steps" :key="index">
          <element-styled-content :content="step.content" class="pb-3"></element-styled-content>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>
  </v-card>
</template>

<script>
import ElementStyledContent from '@/views/products/components/element-styled-content';
import PrimaryButton from '@/views/components/button/primary-button';

export default {
  name: 'introduction-carousel',
  components: { PrimaryButton, ElementStyledContent },
  props: {
    steps: Array,
  },
  data() {
    return { currentStep: 0 };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/src/mixins.scss';

.introduction-carousel {

  &,
  .v-window__container,
  .v-window__container .v-window-item,
  .v-window__container .v-window-item .v-carousel__item {
    height: auto !important;
  }

  .v-carousel__controls {
    margin-bottom: 20px;
    background: transparent;
    z-index: 1;

    .v-item-group button.v-carousel__controls__item {
      &, .v-icon {
        background-color: var(--StarlingDarkGrey);
        color: var(--StarlingDarkGrey);
      }

      &.v-btn--active {
        &, .v-icon {
          background-color: var(--StarlingPrimary1);
          color: var(--StarlingPrimary1);
        }
      }
    }
  }

  .v-carousel.mobile .v-carousel__controls {
    margin-bottom: 15px;
    background: transparent;

    .v-item-group .v-btn {
      width: 20px !important;
      height: 20px !important;

      .v-icon {
        font-size: 10px !important;
      }
    }
  }

  .navigation-button {
    position: absolute;
    bottom: 5px;
    z-index: 2;

    @include respond(small_only) {
      bottom: 15px;
    }

    @include respond(medium_only) {
      bottom: 10px;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  .action-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}
</style>
