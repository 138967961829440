










import Vue from 'vue';
import { HomeStepTypeEnum, IHomeDailyProgressStep } from '@/scripts/store/modules/home/types';
import HomeStep from '@/views/home/steps/home-step.vue';
import VimeoVideo from '@/views/components/utilities/vimeo-video.vue';

export default Vue.extend({
  name: 'home-video',
  components: {
    VimeoVideo,
    HomeStep,
  },
  props: {
    step: {
      type: Object as () => IHomeDailyProgressStep,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      vimeoData: null,
    };
  },
  mounted() {
    this.loading = false;
    if (this.step.content) {
      this.$http.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${this.step.content}`)
        .then((res: any) => {
          this.vimeoData = res.data;
        }).finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    onStepCompleted(evt: any) {
      if (!this.step.completed) {
        this.$store.dispatch('home/completeDailyStep', HomeStepTypeEnum.VIDEO).finally(() => {
          this.$log.debug('Video step completed', this.step);
        });
      }
    },
    onVideoEvent(evt: string) {
      this.$store.dispatch('therapy/watchVideoEvent', { videoId: this.step.content, status: evt });
    },
  },
});
