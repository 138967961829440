var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "introduction-carousel",
      staticStyle: { "min-height": "700px" },
      attrs: { light: "", elevation: "0" }
    },
    [
      _c(
        "v-btn",
        {
          staticStyle: { "margin-right": "-15px", "margin-top": "40px" },
          attrs: {
            absolute: "",
            light: "",
            fab: "",
            top: "",
            right: "",
            flat: "",
            large: _vm.$vuetify.breakpoint.smAndUp
          },
          on: { click: _vm.onClose }
        },
        [_c("v-icon", [_vm._v("close")])],
        1
      ),
      _vm.steps.length > 1 && _vm.currentStep > 0
        ? _c(
            "v-btn",
            {
              staticClass: "navigation-button left",
              attrs: {
                fab: "",
                flat: "",
                large: _vm.$vuetify.breakpoint.smAndUp
              },
              on: {
                click: function($event) {
                  _vm.currentStep--
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
            1
          )
        : _vm._e(),
      _vm.steps.length > 1 && _vm.currentStep < _vm.steps.length - 1
        ? _c(
            "v-btn",
            {
              staticClass: "navigation-button right",
              attrs: {
                fab: "",
                flat: "",
                large: _vm.$vuetify.breakpoint.smAndUp
              },
              on: {
                click: function($event) {
                  _vm.currentStep++
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.steps.length - 1
        ? _c(
            "primary-button",
            {
              staticClass: "action-button",
              attrs: { large: "" },
              on: { click: _vm.onClose }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("app.home.introduction.action")) +
                  "\n  "
              )
            ]
          )
        : _vm._e(),
      _c(
        "v-card-text",
        {
          staticClass: "pa-0",
          staticStyle: { height: "100%", "min-height": "700px" }
        },
        [
          _c(
            "v-carousel",
            {
              class: {
                mobile: _vm.$vuetify.breakpoint.smAndDown,
                desktop: _vm.$vuetify.breakpoint.mdAndUp,
                "pa-3": true
              },
              staticStyle: { "min-height": "700px" },
              attrs: {
                light: "",
                "prev-icon": false,
                "next-icon": false,
                "hide-delimiters": _vm.currentStep === _vm.steps.length - 1,
                "hide-controls": _vm.currentStep === _vm.steps.length - 1,
                interval: 15000000,
                continuous: false,
                height: "100%",
                cycle: false
              },
              model: {
                value: _vm.currentStep,
                callback: function($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep"
              }
            },
            _vm._l(_vm.steps, function(step, index) {
              return _c(
                "v-carousel-item",
                { key: index },
                [
                  _c("element-styled-content", {
                    staticClass: "pb-3",
                    attrs: { content: step.content }
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }