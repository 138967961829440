var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        [
          _c("v-hover", {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var hover = ref.hover
                    return _c(
                      "v-layout",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { "gap-xs-2": "", "gap-md-3": "" },
                        on: { click: _vm.onCardClick }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { shrink: "", "mb-3": !_vm.showFooter } },
                          [
                            _c("vertical-steps", {
                              attrs: { step: _vm.verticalStep }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          {
                            staticClass: "step-container",
                            class: {
                              flat: _vm.flat,
                              recommended: !_vm.flat && _vm.step.recommended,
                              completed: !_vm.flat && _vm.step.completed
                            },
                            attrs: { "mb-3": !_vm.showFooter }
                          },
                          [
                            _vm.title
                              ? _c(
                                  "h1",
                                  {
                                    staticClass: "starling-h1 mb-3",
                                    class: _vm.$vuetify.breakpoint.name
                                  },
                                  [_vm._v(_vm._s(_vm.title))]
                                )
                              : _vm._e(),
                            _c(
                              "v-card",
                              {
                                staticClass: "step-card banner-container",
                                class:
                                  "elevation-" + (hover ? 12 : _vm.elevation)
                              },
                              [_vm._t("default")],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm.showFooter
            ? _c(
                "v-layout",
                { attrs: { "gap-xs-2": "", "gap-md-3": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c("vertical-steps", {
                        staticStyle: { opacity: "0" },
                        attrs: { step: _vm.verticalStep }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "step-container" },
                    [
                      _c("v-expand-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showFooter,
                                expression: "showFooter"
                              }
                            ],
                            staticClass: "mt-1 mb-3"
                          },
                          [_vm._t("footer")],
                          2
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }