var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "home-step",
        {
          staticClass: "home-exercise",
          attrs: { step: _vm.step },
          on: { selected: _vm.onStepSelected },
          scopedSlots: _vm._u(
            [
              _vm.showFooter
                ? {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "v-layout",
                          { attrs: { row: "" } },
                          [
                            _vm.allCompleted
                              ? _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      xs12: "",
                                      "text-center": "",
                                      "align-self-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "starling-explanatory StarlingPrimary1--text"
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t(
                                                "app.home.recommendations.exercise.all_complete.step_completed.congratulations"
                                              )
                                            ) +
                                            "\n        "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "-ma-2": "" } },
                                  [
                                    _c("element-styled-content", {
                                      attrs: {
                                        content: _vm.stepCompletedFooterContent,
                                        "is-card-content": ""
                                      }
                                    })
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _c(
            "v-layout",
            { attrs: { "gap-xs-2": "", wrap: _vm.$vuetify.breakpoint.xsOnly } },
            [
              _vm.isFirstCheckup
                ? _c("v-flex", { attrs: { xs12: "", "pa-2": "" } }, [
                    _c("p", {
                      staticClass: "starling-text StarlingLightGrey--text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "app.home.recommendations.exercise.checkup_first.title"
                          )
                        )
                      }
                    })
                  ])
                : _vm.showRecommendedCard
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("element-styled-content", {
                        attrs: {
                          content: _vm.recommendedCardContent,
                          "is-card-content": ""
                        }
                      })
                    ],
                    1
                  )
                : _vm.showStepCompletedCard
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("element-styled-content", {
                        attrs: {
                          content: _vm.stepCompletedCardContent,
                          "is-card-content": ""
                        }
                      })
                    ],
                    1
                  )
                : _vm.showAllCompletedCard
                ? [
                    _vm.step.completed
                      ? _c("v-flex", { attrs: { xs12: "", "pa-2": "" } }, [
                          _c(
                            "h5",
                            {
                              staticClass: "starling-h5 StarlingPrimary1--text"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.home.recommendations.exercise.all_complete.step_completed.title"
                                  )
                                )
                              )
                            ]
                          )
                        ])
                      : _c("v-flex", { attrs: { xs12: "", "pa-2": "" } }, [
                          _c(
                            "h5",
                            {
                              staticClass: "starling-h5 StarlingPrimary1--text"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.home.recommendations.exercise.all_complete.title"
                                  )
                                )
                              )
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "starling-text StarlingPrimary1--text"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.home.recommendations.exercise.all_complete.subtitle"
                                  )
                                )
                              )
                            ]
                          )
                        ])
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }