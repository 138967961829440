<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">

      <v-card>
        <v-card-title class="headline grey lighten-2"> {{$t('app.home.memberIdModal.title')}} </v-card-title>

        <v-card-text>{{this.$store.getters.user.organization}} {{$t('app.home.memberIdModal.description')}}</v-card-text>

        <v-card-text class="mb-1">
          <v-text-field
            class="mt-3"
            autocomplete="off"
            id="field-input"
            :label="$t('app.home.memberIdModal.title')"
            :rules="[v=> v && v.length >= this.min && v.trim().length >= this.min || $t('common.errors.required')]"
            minlength="2"
            :maxlength="this.max"
            v-model="memberId"
            type="text"
            required
          >
          </v-text-field>
        </v-card-text>

        <v-card-text class='mb-3'><strong>{{$t('app.home.memberIdModal.contact_text')}}</strong></v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addMemberCode"> {{$t('app.home.memberIdModal.button')}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'memberIdModal',
  props: {},
  data() {
    return {
      dialog: false,
      memberId: null,
      memberIdConfig: null,
      max: 20,
      min: 0,
    };
  },
  mounted () {
    if (!this.$store.getters.user.organizationMemberCode) {
      this.$store.dispatch('getMemberIdConfig', { id: this.$store.getters.user.id }).then(res => {
        this.memberIdConfig = res;
        if (this.memberIdConfig.active) {
          this.dialog = true;
          this.max = this.memberIdConfig.max;
          this.min = this.memberIdConfig.min;
        }
      });
    }
  },
  methods: {
    addMemberCode () {
      if (this.memberId && this.memberId.length > 0) {
        this.$store.dispatch('setOrganizationMemberCode', { userId: this.$store.getters.user.id, memberId: this.memberId }).then(res => {
          this.memberIdConfig = res;
          this.dialog = false;
        });
      }
    },
  },
};
</script>
