var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dailyProgress
    ? _c(
        "div",
        [
          _c(
            "v-dialog",
            {
              ref: "dateDialog",
              attrs: {
                persistent: "",
                lazy: "",
                "full-width": "",
                width: "290px",
                "content-class": "radius-15"
              },
              model: {
                value: _vm.dateModalOpen,
                callback: function($$v) {
                  _vm.dateModalOpen = $$v
                },
                expression: "dateModalOpen"
              }
            },
            [
              _c(
                "v-date-picker",
                {
                  attrs: {
                    color: "primary",
                    min: _vm.minDate,
                    locale: _vm.currentLanguage,
                    scrollable: ""
                  },
                  model: {
                    value: _vm.innerValue,
                    callback: function($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue"
                  }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { flat: "" }, on: { click: _vm.onModalClose } },
                    [_vm._v(_vm._s(_vm.$t("common.actions.cancel")))]
                  ),
                  _c(
                    "primary-button",
                    { on: { click: _vm.onSetChosenCheckupDate } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("common.actions.save")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gradient-border-button",
            {
              attrs: {
                active: _vm.checkUp && _vm.checkUp.status === _vm.modes.due
              },
              on: { click: _vm.onModalOpen }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t(
                      "app.home.recommendations.track_progress.chart.buttons.due",
                      { date: _vm.displayDate }
                    )
                  ) +
                  "\n    "
              ),
              _c(
                "v-icon",
                {
                  staticClass: "StarlingLighterGrey--bg",
                  staticStyle: {
                    border: "3px solid white",
                    "border-radius": "50%",
                    height: "40px",
                    width: "40px",
                    "margin-right": "-20px"
                  },
                  attrs: { right: "" }
                },
                [_vm._v("mdi-pencil-outline")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }