var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      class: { mobile: _vm.$vuetify.breakpoint.smAndDown },
      attrs: { column: "", "fill-height": "" }
    },
    [
      _c(
        "v-flex",
        { staticStyle: { "align-content": "center" } },
        [
          _c("step-icon", {
            attrs: { step: _vm.step, large: "", "show-text": false }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }