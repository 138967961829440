

















































import Vue from 'vue';
import { IHomeDailyProgress, IHomeDailyProgressStep } from '@/scripts/store/modules/home/types';
import HomeStep from '@/views/home/steps/home-step.vue';
import CheckupResultsRadarchart from '@/views/home/components/checkup-results-radarchart.vue';
import moment from 'moment';

export interface ICheckup {
  checkupCompleted: boolean,
  remainingDays: number,
  totalDays: number,
}

export default Vue.extend({
  name: 'home-track-progress',
  components: {
    CheckupResultsRadarchart,
    HomeStep,
  },
  props: {
    step: {
      type: Object as () => IHomeDailyProgressStep,
      required: true,
    },
  },
  data() {
    return {
      assessmentChart: {
        values: {
          ENERGY: {
            simplifiedValue: 'NA',
          },
          'CD-RISC2': {
            simplifiedValue: 'NA',
          },
          GAD7: {
            simplifiedValue: 'NA',
          },
          PHQ9: {
            simplifiedValue: 'NA',
          },
          STRESS: {
            simplifiedValue: 'NA',
          },
        },
      },
    };
  },
  computed: {
    dailyProgress(): IHomeDailyProgress {
      return this.$store.getters['home/dailyProgress'];
    },
    checkup(): ICheckup | undefined {
      if (!this.dailyProgress) return undefined;
      return {
        checkupCompleted: !this.dailyProgress.firstCheckup,
        remainingDays: this.dailyProgress.checkupRemainingDays,
        totalDays: this.dailyProgress.checkupTotalDays,
      };
    },
    nextCheckupDate(): String {
      const currentDate = moment(new Date());
      const nextDate = this.checkup?.remainingDays ? currentDate.add(this.checkup.remainingDays, 'days') : currentDate;
      return nextDate.format('MMM DD').toString();
    },
    isFirstCheckup(): boolean {
      return !this.checkup?.checkupCompleted;
    },
    isCheckupDue(): boolean {
      return !this.checkup?.remainingDays || this.checkup.remainingDays < 0;
    },
    stepTitle(): string {
      if (this.isFirstCheckup) return 'checkup_first';
      if (this.isCheckupDue) return 'checkup_due';
      return 'checkup_completed';
    },
    checkupFrequencyWeeks(): number {
      return this.dailyProgress.checkupLastFrequency;
    },
  },
  mounted() {
    if (this.checkup?.checkupCompleted) {
      this.$store.dispatch('assessment/getMostRecentAssessments').then(res => {
        this.assessmentChart.values = res;
      });
    }
  },
  methods: {
    onStepSelected() {
      if (this.isFirstCheckup || this.isCheckupDue) {
        this.$router.push({ name: 'checkup' });
      } else {
        this.$router.push({ name: 'checkup-results' });
      }
    },
  },
});
