








































import Vue from 'vue';
import { HomeStepTypeEnum, IHomeDailyProgress, IHomeDailyProgressStep } from '@/scripts/store/modules/home/types';
import { IStyleContentAnimation } from '@/scripts/store/modules/therapy/types';
import ElementPartAnimation from '@/views/products/components/elements-parts/element-part-animation.vue';
import HomeBuildSkills from '@/views/home/steps/home-build-skills.vue';
import HomeConnectOthers from '@/views/home/steps/home-connect-others.vue';
import HomePractice from './home-practice.vue';
import HomeVideo from '@/views/home/steps/home-video.vue';
import HomeExercise from '@/views/home/steps/home-exercise.vue';

export default Vue.extend({
  name: 'home-recommendations',
  components: {
    HomeExercise,
    HomeVideo,
    ElementPartAnimation,
    HomeBuildSkills,
    HomeConnectOthers,
    HomePractice,
  },
  data() {
    return {
      loading: true,
      continueSteps: undefined as IHomeDailyProgressStep[] | undefined,
      recommendedSteps: undefined as IHomeDailyProgressStep[] | undefined,
      homeSteps: HomeStepTypeEnum,
    };
  },
  computed: {
    dailyProgress(): IHomeDailyProgress {
      return this.$store.getters['home/dailyProgress'];
    },
    allCompletedAnimation(): IStyleContentAnimation | undefined {
      if (this.continueSteps?.some(step => !step.completed) || this.recommendedSteps?.some(step => !step.completed)) return;
      return {
        content: '',
        id: 'rainbow',
        key: 'animation',
        delay: 3,
      } as IStyleContentAnimation;
    },
  },
  watch: {
    dailyProgress: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.continueSteps = undefined;
          this.recommendedSteps = undefined;
        } else {
          this.continueSteps = newVal.continueSteps;
          this.recommendedSteps = newVal.recommendedSteps;
        }
      },
    },
  },
  mounted() {
    this.loading = false;
  },
  methods: {
  },
});
