var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "home-step",
        { staticClass: "home-video", attrs: { step: _vm.step } },
        [
          _c(
            "v-layout",
            { attrs: { "gap-xs-2": "", wrap: _vm.$vuetify.breakpoint.xsOnly } },
            [
              _c(
                "v-flex",
                {
                  staticStyle: { "border-radius": "15px" },
                  attrs: { xs12: "" }
                },
                [
                  _c("vimeo-video", {
                    attrs: { "video-id": _vm.step.content },
                    on: {
                      play: _vm.onStepCompleted,
                      videoEvent: _vm.onVideoEvent
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }