var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "home-step",
    {
      staticClass: "home-connect-others",
      attrs: { step: _vm.step },
      on: { selected: _vm.onStepSelected },
      scopedSlots: _vm._u(
        [
          _vm.step.completed && _vm.step.lastCompleted
            ? {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              "text-center": "",
                              "align-self-center": ""
                            }
                          },
                          [
                            _c("p", {
                              staticClass:
                                "starling-explanatory StarlingPrimary1--text",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.activeContent.congratulations
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "gap-xs-2": "" } },
        [
          !_vm.step.completed
            ? [
                _c("v-flex", { attrs: { xs8: "", "py-2": "", "pl-2": "" } }, [
                  _c(
                    "h5",
                    { staticClass: "starling-h5 StarlingPrimary1--text" },
                    [_vm._v(_vm._s(_vm.activeContent.title))]
                  ),
                  _c("p", { staticClass: "starling-text" }, [
                    _c("b", [_vm._v(_vm._s(_vm.activeContent.description))]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.activeContent.time) +
                        "\n        "
                    )
                  ])
                ]),
                _c(
                  "v-flex",
                  { attrs: { xs4: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", "fill-height": "" } },
                      [
                        _c(
                          "v-flex",
                          {
                            staticClass: "thumbnail-container",
                            attrs: { xs12: "" }
                          },
                          [
                            _vm.activeContent.id ===
                            _vm.rotationOptions.COMMUNITY
                              ? _c("img", {
                                  staticClass: "thumbnail community",
                                  attrs: {
                                    alt: "",
                                    src: require("@/assets/svg/starling_community.svg")
                                  }
                                })
                              : _vm.vimeoData
                              ? _c("img", {
                                  staticClass: "thumbnail",
                                  attrs: {
                                    alt: "",
                                    src: _vm.vimeoData.thumbnail_url
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _c("v-flex", { attrs: { xs12: "", "pa-2": "" } }, [
                  _c(
                    "h5",
                    { staticClass: "starling-h5 StarlingPrimary1--text" },
                    [_vm._v(_vm._s(_vm.activeContent.titleCompleted))]
                  )
                ])
              ]
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", width: "100%" },
          model: {
            value: _vm.displayModal,
            callback: function($$v) {
              _vm.displayModal = $$v
            },
            expression: "displayModal"
          }
        },
        [
          _vm.videoData && _vm.displayModal
            ? _c(
                "v-card",
                [
                  _c(
                    "v-card-actions",
                    { staticClass: "pb-0" },
                    [
                      _c("v-spacer"),
                      _c("v-icon", { on: { click: _vm.closeModal } }, [
                        _vm._v("close")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-center": "",
                        "justify-center": "",
                        "pb-5": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs10: "", sm8: "", xl6: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("h3", { staticClass: "starling-h3" }, [
                                  _vm._v(_vm._s(_vm.activeContent.title))
                                ])
                              ]),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("p", { staticClass: "starling-body" }, [
                                  _vm._v(_vm._s(_vm.activeContent.subtitle))
                                ])
                              ]),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("vimeo-video", {
                                    attrs: {
                                      videoId: _vm.videoData.vimeoId,
                                      identifier: _vm.videoData.identifier,
                                      transcript: _vm.videoData.transcript
                                    },
                                    on: { videoEvent: _vm.onVideoEvent }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "text-center": "",
                                    "pt-3": ""
                                  }
                                },
                                [
                                  _c(
                                    "primary-button",
                                    {
                                      attrs: { large: "" },
                                      on: { click: _vm.closeModal }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("common.actions.done")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }