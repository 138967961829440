var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      class: {
        "vertical-line": !_vm.step.isLast,
        mobile: _vm.$vuetify.breakpoint.smAndDown
      },
      attrs: { column: "", "fill-height": "" }
    },
    [
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [_c("step-icon", { attrs: { step: _vm.step, large: "" } })],
        1
      ),
      !_vm.step.isLast
        ? _c("v-flex", {
            class: [
              _vm.step.status.toLowerCase(),
              { animation: _vm.step.animation }
            ]
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }