




































import Vue from 'vue';
import { mapState } from 'vuex';
import {
  IHomeDailyProgress,
  IHomeDailyProgressCheckup,
  ProgressTrackerModes,
} from '@/scripts/store/modules/home/types';
import moment, { Moment } from 'moment';
import GradientBorderButton from '@/views/components/button/gradient-border-button.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'home-checkup-chosen-date',
  components: { PrimaryButton, GradientBorderButton },
  data() {
    return {
      dateModalOpen: false,
      innerValue: '',
      modes: ProgressTrackerModes,
    };
  },
  computed: {
    checkUp(): IHomeDailyProgressCheckup {
      if (!this.dailyProgress) {
        return {
          chosenCheckupDate: '',
          first: true,
          status: ProgressTrackerModes.due,
          frequency: 0,
          lastCheckupDate: '',
          nextCheckupDate: '',
          remainingDays: 0,
          totalDays: 0,
        };
      }
      return this.dailyProgress.checkUp;
    },
    minDate() {
      const checkUp: IHomeDailyProgressCheckup = this.checkUp;
      const moments: Moment[] = [ moment() ];
      if (checkUp.nextCheckupDate) {
        moments.push(moment(checkUp.nextCheckupDate));
      }
      const date: Moment = moment.max(moments);
      return date.toISOString(true);
    },
    dailyProgress(): IHomeDailyProgress {
      return this.$store.getters['home/dailyProgress'] as IHomeDailyProgress;
    },
    ...mapState({
      currentLanguage() {
        return this.$store.getters.currentLanguage;
      },
    }),
    displayDate(): string {
      const checkUp: IHomeDailyProgressCheckup = this.checkUp;
      const currentLanguage: string = this.currentLanguage;

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const chosenCheckupDate = checkUp.chosenCheckupDate ? moment(checkUp.chosenCheckupDate).toDate() : null;
      const nextCheckupDate = checkUp.nextCheckupDate ? moment(checkUp.nextCheckupDate).toDate() : null;
      if ((nextCheckupDate && nextCheckupDate < today) && (!chosenCheckupDate || chosenCheckupDate < today)) {
        return '';
      }
      const date: Date | null = checkUp.chosenCheckupDate ? moment(checkUp.chosenCheckupDate).toDate() : checkUp.nextCheckupDate ? moment(checkUp.nextCheckupDate).toDate() : null;
      return date ? date.toLocaleDateString(currentLanguage, { day: 'numeric', month: 'short' }) : '';
    },
  },
  mounted() {
    this.innerValue = this.dailyProgress.checkUp.chosenCheckupDate;
  },
  methods: {
    onSetChosenCheckupDate() {
      if (this.innerValue) {
        this.$store.dispatch('home/updateChosenCheckupDate', this.innerValue).then(() => {
          return this.$store.dispatch('home/getDailyProgress');
        }).finally(() => {
          this.dateModalOpen = false;
        });
      }
    },
    onModalOpen() {
      this.dateModalOpen = true;
    },
    onModalClose() {
      this.dateModalOpen = false;
    },
  },
});
