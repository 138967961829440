var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "v-flex",
        { attrs: { fluid: "", "pa-0": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.continueSteps && _vm.continueSteps.length
                ? [
                    _c(
                      "v-flex",
                      {
                        staticClass: "mb-3",
                        class: {
                          "first-header": _vm.$vuetify.breakpoint.mdAndUp
                        },
                        attrs: { xs12: "" }
                      },
                      [
                        _c("h1", { staticClass: "starling-h1" }, [
                          _vm._v(
                            _vm._s(_vm.$t("app.home.subheader.continue_title"))
                          )
                        ])
                      ]
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "mb-3": "" } },
                      _vm._l(_vm.continueSteps, function(step) {
                        return _c(
                          "div",
                          { key: step.order },
                          [
                            step.type === _vm.homeSteps.SESSION
                              ? _c("home-build-skills", {
                                  attrs: { step: step }
                                })
                              : _vm._e(),
                            step.type === _vm.homeSteps.PRACTICE
                              ? _c("home-practice", { attrs: { step: step } })
                              : _vm._e(),
                            step.type === _vm.homeSteps.EXERCISE
                              ? _c("home-exercise", { attrs: { step: step } })
                              : _vm._e(),
                            step.type === _vm.homeSteps.ROTATION
                              ? _c("home-connect-others", {
                                  attrs: { step: step }
                                })
                              : _vm._e(),
                            step.type === _vm.homeSteps.VIDEO
                              ? _c("home-video", { attrs: { step: step } })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                : _vm._e(),
              _vm.recommendedSteps && _vm.recommendedSteps.length
                ? [
                    _c(
                      "v-flex",
                      {
                        staticClass: "mb-3",
                        class: {
                          "first-header": _vm.$vuetify.breakpoint.mdAndUp
                        },
                        attrs: { xs12: "" }
                      },
                      [
                        _c("h1", { staticClass: "starling-h1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("app.home.subheader.recommendation_title")
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "mb-3": "" } },
                      _vm._l(_vm.recommendedSteps, function(step) {
                        return _c(
                          "div",
                          { key: step.order },
                          [
                            step.type === _vm.homeSteps.SESSION
                              ? _c("home-build-skills", {
                                  attrs: { step: step }
                                })
                              : _vm._e(),
                            step.type === _vm.homeSteps.PRACTICE
                              ? _c("home-practice", { attrs: { step: step } })
                              : _vm._e(),
                            step.type === _vm.homeSteps.EXERCISE
                              ? _c("home-exercise", { attrs: { step: step } })
                              : _vm._e(),
                            step.type === _vm.homeSteps.ROTATION
                              ? _c("home-connect-others", {
                                  attrs: { step: step }
                                })
                              : _vm._e(),
                            step.type === _vm.homeSteps.VIDEO
                              ? _c("home-video", { attrs: { step: step } })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                      _vm.allCompletedAnimation
                        ? _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c("element-part-animation", {
                                attrs: { part: _vm.allCompletedAnimation }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }