var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        {
          staticClass: "mb-3",
          class: { "first-header": _vm.$vuetify.breakpoint.mdAndUp },
          staticStyle: { position: "relative" },
          attrs: { xs12: "" }
        },
        [
          _c(
            "h1",
            {
              staticClass: "starling-h1",
              staticStyle: { display: "inline-block" }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.checkup && _vm.checkup.status === _vm.Modes.completed
                    ? _vm.$t("app.home.subheader.your_progress")
                    : _vm.$t("app.home.subheader.track_your_progress")
                )
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "text-center cursor-pointer",
              staticStyle: {
                display: "inline-block",
                position: "absolute",
                right: "0",
                top: "calc(50% - 24.5px)"
              },
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  staticStyle: { width: "25px", height: "25px" },
                  attrs: { color: "primary" }
                },
                [_vm._v(_vm._s(_vm.show ? "visibility" : "visibility_off"))]
              ),
              _c(
                "p",
                {
                  staticClass: "primary--text pa-0 ma-0",
                  staticStyle: { "font-size": "10px", "line-height": "10px" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.show
                        ? _vm.$t("common.actions.hide")
                        : _vm.$t("common.actions.show")
                    )
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          attrs: { xs12: "", "mt-2": "", "mb-3": "" }
        },
        [
          _vm.dailyProgress && _vm.checkup
            ? _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-center": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "fill-height": "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs3: _vm.$vuetify.breakpoint.mdAndUp,
                                xs2: _vm.$vuetify.breakpoint.smOnly,
                                xs1: _vm.$vuetify.breakpoint.xsOnly
                              }
                            },
                            [
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          height: "100%"
                                        }
                                      },
                                      [
                                        _vm.checkup.status === _vm.Modes.due
                                          ? [
                                              _c("home-checkup-chosen-date", {
                                                staticStyle: {
                                                  position: "absolute",
                                                  bottom: "40px",
                                                  left: "0"
                                                }
                                              }),
                                              _c(
                                                "primary-button",
                                                {
                                                  staticStyle: {
                                                    position: "absolute",
                                                    bottom: "0",
                                                    left: "0"
                                                  },
                                                  on: { click: _vm.goToCheckup }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "app.home.recommendations.track_progress.chart.buttons.check_up"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm.checkup.status ===
                                            _vm.Modes.upcoming
                                          ? [
                                              _c("home-checkup-chosen-date", {
                                                staticStyle: {
                                                  position: "absolute",
                                                  bottom: "0",
                                                  left: "0"
                                                }
                                              })
                                            ]
                                          : _vm.checkup.status ===
                                            _vm.Modes.completed
                                          ? [
                                              _c("home-checkup-chosen-date", {
                                                staticStyle: {
                                                  position: "absolute",
                                                  bottom: "0",
                                                  left: "0"
                                                }
                                              })
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "chart-container",
                              attrs: {
                                xs6: _vm.$vuetify.breakpoint.mdAndUp,
                                xs8: _vm.$vuetify.breakpoint.smOnly,
                                xs10: _vm.$vuetify.breakpoint.xsOnly
                              }
                            },
                            [
                              _c(
                                "div",
                                { on: { click: _vm.clickRadarChart } },
                                [
                                  _c("checkup-results-radarchart", {
                                    staticClass: "chart",
                                    attrs: {
                                      "canvas-width": "100%",
                                      "show-labels": true,
                                      values: _vm.assessmentChart.values,
                                      mode: _vm.checkup
                                        ? _vm.checkup.status
                                        : undefined
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.$vuetify.breakpoint.smAndDown
                                ? [
                                    _vm.checkup.status === _vm.Modes.due
                                      ? [
                                          _c("home-checkup-chosen-date", {
                                            staticStyle: {
                                              float: "left",
                                              display: "inline-block"
                                            }
                                          }),
                                          _c(
                                            "primary-button",
                                            {
                                              staticStyle: {
                                                float: "right",
                                                display: "inline-block"
                                              },
                                              on: { click: _vm.goToCheckup }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "app.home.recommendations.track_progress.chart.buttons.check_up"
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm.checkup.status ===
                                        _vm.Modes.upcoming
                                      ? [
                                          _c("home-checkup-chosen-date", {
                                            attrs: { styl: "" }
                                          })
                                        ]
                                      : _vm.checkup.status ===
                                        _vm.Modes.completed
                                      ? [_c("home-checkup-chosen-date")]
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _c("v-flex", {
                            attrs: {
                              xs3: _vm.$vuetify.breakpoint.mdAndUp,
                              xs2: _vm.$vuetify.breakpoint.smOnly,
                              xs1: _vm.$vuetify.breakpoint.smAndDown
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }