var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "home-step",
    {
      staticClass: "home-track-progress",
      attrs: { step: _vm.step },
      on: { selected: _vm.onStepSelected },
      scopedSlots: _vm._u(
        [
          _vm.step.completed && _vm.step.lastCompleted
            ? {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              "text-center": "",
                              "align-self-center": ""
                            }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "starling-explanatory StarlingPrimary1--text"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t(
                                        "app.home.recommendations.track_progress.checkup_completed.congratulations",
                                        { weeks: _vm.checkupFrequencyWeeks }
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _vm.isFirstCheckup || _vm.isCheckupDue
            ? [
                _c("v-flex", { attrs: { xs8: "", "pa-2": "" } }, [
                  _c(
                    "h5",
                    { staticClass: "starling-h5 StarlingPrimary1--text" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.home.recommendations.track_progress.checkup_due.title"
                          )
                        )
                      )
                    ]
                  ),
                  _c("p", { staticClass: "starling-text" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.home.recommendations.track_progress.checkup_due.description"
                          )
                        )
                      )
                    ]),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "app.home.recommendations.track_progress.checkup_due.checkup_time"
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _c(
                  "v-flex",
                  { attrs: { xs4: "", "text-center": "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", "fill-height": "" } },
                      [
                        _c(
                          "v-flex",
                          {
                            staticClass: "chart-container",
                            attrs: { xs12: "" }
                          },
                          [
                            _vm.assessmentChart.values
                              ? _c("checkup-results-radarchart", {
                                  tag: "checkup-results-radarchart",
                                  staticClass: "chart",
                                  attrs: {
                                    "canvas-width": "100%",
                                    "show-labels": false,
                                    values: _vm.assessmentChart.values
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _c("v-flex", { attrs: { xs12: "", "pa-2": "" } }, [
                  _c(
                    "h5",
                    { staticClass: "starling-h5 StarlingPrimary1--text" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "app.home.recommendations.track_progress.checkup_completed.title"
                          )
                        )
                      )
                    ]
                  ),
                  _c(
                    "p",
                    { staticClass: "starling-text StarlingPrimary1--text" },
                    [
                      _c("v-icon", { attrs: { color: "StarlingPrimary1" } }, [
                        _vm._v("event")
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "app.home.recommendations.track_progress.checkup_completed.next_checkup"
                            )
                          ) +
                          "\n          "
                      ),
                      _c("strong", [_vm._v(_vm._s(_vm.nextCheckupDate))])
                    ],
                    1
                  )
                ])
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }