










import Vue from 'vue';
import { HomeStepTypeEnum, IHomeDailyProgressStep } from '@/scripts/store/modules/home/types';
import HomeStep from '@/views/home/steps/home-step.vue';
import VimeoVideo from '@/views/components/utilities/vimeo-video.vue';

export default Vue.extend({
  name: 'home-video',
  components: {
    VimeoVideo,
    HomeStep,
  },
  props: {
    step: {
      type: Object as () => IHomeDailyProgressStep,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      therapyVideo: null as any,
      vimeoData: null,
    };
  },
  mounted() {
    this.loading = false;
    if (this.step.content) {
      this.$store.dispatch('therapy/getTherapyVideo', this.step.content).then(video => {
        this.therapyVideo = video;
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  methods: {
    onStepCompleted(evt: any) {
      if (!this.step.completed) {
        this.$store.dispatch('home/completeDailyStep', HomeStepTypeEnum.VIDEO).finally(() => {
          this.$log.debug('Video step completed', this.step);
        });
      }
    },
    onVideoEvent(evt: string) {
      const vimeoId = (this.therapyVideo as any)?.vimeoId;
      if (vimeoId) {
        this.$store.dispatch('therapy/watchVideoEvent', { videoId: vimeoId, status: evt });
      }
    },
  },
});
