



















































import Vue from 'vue';
import { IStyleContent, ITherapyContentGroup, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import { HomeStepTypeEnum, IHomeDailyProgressStep } from '@/scripts/store/modules/home/types';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';
import HomeStep from '@/views/home/steps/home-step.vue';

export default Vue.extend({
  name: 'home-exercise',
  components: {
    ElementStyledContent,
    HomeStep,
  },
  props: {
    step: {
      type: Object as () => IHomeDailyProgressStep,
      required: true,
    },
  },
  data() {
    return {
      nextExercise: undefined as ITherapyContentGroup | undefined,
      completedContent: undefined as ITherapyContentGroup | undefined,
      loading: true,
      allCompleted: false,
    };
  },
  computed: {
    showRecommendedCard(): boolean {
      return (!this.step.completed || !this.stepCompletedCardContent) && !!this.recommendedCardContent;
    },
    showStepCompletedCard(): boolean {
      return this.step.completed && !!this.stepCompletedCardContent;
    },
    showAllCompletedCard(): boolean {
      return this.allCompleted || (!this.showRecommendedCard && !this.showStepCompletedCard);
    },
    showFooter(): boolean {
      return this.step.lastCompleted && (!!this.stepCompletedFooterContent || this.allCompleted);
    },
    recommendedCardContent(): IStyleContent[] | undefined {
      return this.nextExercise?.group?.[0].styleContent;
    },
    stepCompletedCardContent(): IStyleContent[] | undefined {
      if (!this.completedContent?.group?.[0].styleContent) return undefined;
      const index = this.completedContent?.group?.[0].styleContent.map(c => c.key).indexOf(StyleTypeEnum.CONTENT);
      if (index >= 0 && index !== this.completedContent?.group?.[0].styleContent.length - 1) {
        return this.completedContent?.group?.[0].styleContent.slice(0, index);
      }
      return undefined;
    },
    stepCompletedFooterContent(): IStyleContent[] | undefined {
      if (!this.completedContent?.group?.[0].styleContent) return undefined;
      const index = this.completedContent?.group?.[0].styleContent?.map(c => c.key).indexOf(StyleTypeEnum.CONTENT);
      if (index < 0) {
        return this.completedContent?.group?.[0].styleContent;
      }
      if (index === this.completedContent?.group?.[0].styleContent?.length - 1) {
        return this.completedContent?.group?.[0].styleContent?.slice(0, index);
      }
      return this.completedContent?.group?.[0].styleContent?.slice(index + 1);
    },
    isFirstCheckup(): boolean {
      return this.step.content === 'FIRST_CHECKUP';
    },
  },
  mounted() {
    this.loadNextExercise();
    if (this.step.completed) {
      this.loadCompletedContent();
    }
  },
  methods: {
    loadNextExercise(): void {
      if (!this.step.content) {
        this.allCompleted = true;
        this.loading = false;
        return;
      }

      if (this.isFirstCheckup) {
        this.loading = false;
        return;
      }

      this.loading = true;

      this.$store.dispatch('therapy/getTherapyPreview', this.step.content).then((res: ITherapyContentGroup) => {
        this.nextExercise = res;
      }).finally(() => {
        this.loading = false;
      });
    },
    loadCompletedContent(): void {
      if (!this.step.completedUen) {
        this.loading = false;
        return;
      }

      this.loading = true;

      this.$store.dispatch('therapy/getTherapyPreview', this.step.completedUen).then((res: ITherapyContentGroup) => {
        this.completedContent = res;
      }).finally(() => {
        this.loading = false;
      });
    },
    onStepSelected(): void {
      if (this.isFirstCheckup) return;
      if (this.showRecommendedCard) {
        this.goToNextExercise();
      } else if (this.showStepCompletedCard) {
        this.goToExerciseMenu();
      } else if (this.showAllCompletedCard) {
        this.completeStep();
      }
    },
    completeStep(): void {
      if (!this.step.completed) {
        this.$store.dispatch('home/completeDailyStep', HomeStepTypeEnum.EXERCISE).finally(() => {
          this.goToExerciseMenu();
        });
      } else {
        this.goToExerciseMenu();
      }
    },
    goToExerciseMenu(): void {
      this.$router.push({ name: 'exercises' });
    },
    goToNextExercise(): void {
      if (this.nextExercise?.sessionUen) {
        this.$router.push({ name: 'exercises-run', params: { uen: this.nextExercise.sessionUen } });
      }
    },
  },
});
