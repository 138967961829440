var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline grey lighten-2" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("app.home.memberIdModal.title")) + " "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  _vm._s(this.$store.getters.user.organization) +
                    " " +
                    _vm._s(_vm.$t("app.home.memberIdModal.description"))
                )
              ]),
              _c(
                "v-card-text",
                { staticClass: "mb-1" },
                [
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      autocomplete: "off",
                      id: "field-input",
                      label: _vm.$t("app.home.memberIdModal.title"),
                      rules: [
                        function(v) {
                          return (
                            (v &&
                              v.length >= this$1.min &&
                              v.trim().length >= this$1.min) ||
                            _vm.$t("common.errors.required")
                          )
                        }
                      ],
                      minlength: "2",
                      maxlength: this.max,
                      type: "text",
                      required: ""
                    },
                    model: {
                      value: _vm.memberId,
                      callback: function($$v) {
                        _vm.memberId = $$v
                      },
                      expression: "memberId"
                    }
                  })
                ],
                1
              ),
              _c("v-card-text", { staticClass: "mb-3" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("app.home.memberIdModal.contact_text")))
                ])
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.addMemberCode }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("app.home.memberIdModal.button")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }