<template>
  <v-btn v-bind="$attrs"
         @click="$emit('click',$event)"
         class="starling-button-gradient-border font-weight-bold ma-0"
         :class="{'primary-large': $attrs.large !== undefined, 'active':$attrs.active}"
         round style="opacity:1;">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'gradient-border-button',
};
</script>

<style>
.starling-button-gradient-border:hover:before {
  background-color: var(--button-hover);
  opacity: var(--button-hover-opacity);
  transition: .3s cubic-bezier(.25, .8, .5, 1);
}

.starling-button-gradient-border {
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 15px;
  background: var(--StarlingLighterGrey) !important;
  cursor: pointer;
  box-shadow: none !important;
  padding: 3px;
}

.starling-button-gradient-border.active {
  background: linear-gradient(180deg, var(--StarlingSuccess), var(--StarlingPrimary2))!important;
}

.starling-button-gradient-border .v-btn__content {
  background-color: white;
  height: 100%;
  width: 100%;
  padding: 3px 11px;
  border-radius: 12px;
}

.starling-button-gradient-border,
.starling-button-gradient-border::after {
  border-radius: 15px;
}
</style>
