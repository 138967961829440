





































import Vue from 'vue';
import { HomeStatusEnum, IHomeDailyProgressStep } from '@/scripts/store/modules/home/types';
import VerticalSteps, { IVerticalStep } from '@/views/components/steppers/vertical-steps.vue';

export default Vue.extend({
  name: 'home-step',
  components: {
    VerticalSteps,
  },
  props: {
    step: {
      type: Object as () => IHomeDailyProgressStep,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    flat: {
      type: Boolean,
      required: false,
      default: false,
    },
    elevation: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  data() {
    return {
      loading: true,
      showFooter: false,
    };
  },
  computed: {
    isFirstCheckup(): boolean {
      return this.step.content === 'FIRST_CHECKUP';
    },
    verticalStep(): IVerticalStep {
      return {
        text: this.step.order.toString(),
        status: this.step.completed ? HomeStatusEnum.COMPLETED : (this.step.recommended ? HomeStatusEnum.RECOMMENDED : HomeStatusEnum.INITIAL),
        isLast: this.step.isLast,
        animation: !!this.step.lastCompleted,
      };
    },
  },
  mounted() {
    setInterval(() => { this.showFooter = true; }, 2500);
  },
  methods: {
    onCardClick() {
      this.$emit('selected');
    },
  },
});
