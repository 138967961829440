









import Vue from 'vue';
import StepIcon, { IStep } from '@/views/components/steppers/step-icon.vue';

export interface IVerticalStep extends IStep {
  isLast?: boolean,
  animation?: boolean,
}

export default Vue.extend({
  name: 'vertical-steps',
  components: {
    StepIcon,
  },
  props: {
    step: {
      type: Object as () => IStep,
      required: true,
    },
  },
});
