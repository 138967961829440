import { render, staticRenderFns } from "./home-connect-others.vue?vue&type=template&id=2adf0ed6&"
import script from "./home-connect-others.vue?vue&type=script&lang=ts&"
export * from "./home-connect-others.vue?vue&type=script&lang=ts&"
import style0 from "./home-connect-others.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VDialog,VFlex,VIcon,VLayout,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-dev-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2adf0ed6')) {
      api.createRecord('2adf0ed6', component.options)
    } else {
      api.reload('2adf0ed6', component.options)
    }
    module.hot.accept("./home-connect-others.vue?vue&type=template&id=2adf0ed6&", function () {
      api.rerender('2adf0ed6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/steps/home-connect-others.vue"
export default component.exports