var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "v-container",
        { attrs: { fluid: "", "pa-0": "" } },
        [
          _vm._l(_vm.steps, function(step) {
            return _c(
              "div",
              { key: step.order },
              [
                step.type === _vm.homeSteps.CHECKUP
                  ? _c("home-track-progress", { attrs: { step: step } })
                  : _vm._e(),
                step.type === _vm.homeSteps.SESSION
                  ? _c("home-build-skills", { attrs: { step: step } })
                  : _vm._e(),
                step.type === _vm.homeSteps.EXERCISE
                  ? _c("home-practice", { attrs: { step: step } })
                  : _vm._e(),
                step.type === _vm.homeSteps.ROTATION
                  ? _c("home-connect-others", { attrs: { step: step } })
                  : _vm._e()
              ],
              1
            )
          }),
          _vm.allCompletedAnimation
            ? _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("element-part-animation", {
                    attrs: { part: _vm.allCompletedAnimation }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }